export default {
  name: "WarehousesDialogAddLayout",
  data() {
    return {
      texts: "",
      itemsCountries: [],
      itemsStates: [],
      screenWidth: 0,
      bLoading: false,
      warehouseName: "",
      warehousePrice: "",
      warehouseCustomsPrice: "",
      wahrehouseAddress: "",
      wahrehouseAddressOptional: "",
      warehouseCountry: "",
      warehouseState: "",
      warehouseCity: "",
      warehousePostalCode: "",
      warehousePhoneNumber: "",
      warehouseEmail: "",
      warehouseLatitude: "",
      warehouseLongitude: "",
      center: { lat: 37.09024, lng: -95.712891 },
      currentPlace: null,
      oPlace: null,
      markers: [],
      mapOptions: {
        scrollwheel: false,
        mapTypeControl: false,
        zoomControl: true,
        disableDoubleClickZoom: true,
        keyboardShortcuts: false,
        streetViewControl: false,
        maxZoom: 10,
        minZoom: 3,
      },
      autoCompleteOptions: {
        componentRestrictions: {
          country: ["us", "mx"],
        },
      },
    };
  },
  beforeMount() {
    this.texts = FILE.warehouseTexts[this.selectLanguage];
    this.getCountries();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.closeButton();
  },
  methods: {
    setPlace: function(place) {
      let split_name = place.formatted_address.split(",");
      let name_address = split_name[0] + "," + split_name[1];

      this.wahrehouseAddress = name_address;
      this.currentPlace = place;
      this.oPlace = place;
      this.exampleFill(place);
      this.addMarker();
    },
    addMarker: function() {
      this.markers = [];
      if (this.currentPlace) {
        this.warehouseLatitude = this.currentPlace.geometry.location.lat();
        this.warehouseLongitude = this.currentPlace.geometry.location.lng();

        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers.push({ position: marker });
        this.center = marker;
        this.currentPlace = null;
      }
    },
    exampleFill: function(place) {
      if (place.address_components) {
        const addressComponents = place.address_components;
        let selectedCountry;
        const countryAddressComponent = addressComponents.find((adc) =>
          adc.types.includes("country")
        );

        if (countryAddressComponent.short_name === "MX") {
          this.warehouseCountry = "México";
          selectedCountry = this.itemsCountries.find(
            (c) => c.sName === "México" || c.sName === "Mexico"
          );
          //sCountryId
          this.warehouseCountry = selectedCountry.sCountryId;
          this.selecState();
        }
        if (countryAddressComponent.short_name === "US") {
          selectedCountry = this.itemsCountries.find(
            (c) =>
              c.sName === "Estados Unidos de América" ||
              c.sName === "United States of America"
          );
          //sCountryId
          this.warehouseCountry = selectedCountry.sCountryId;
          this.selecState();
        }
        // for(let i = 0; i<addressComponents.length; i++){

        //   //administrative_area_level_1 es el type que usa google para los estados
        //   if(addressComponents[i].types.includes('administrative_area_level_1') && selectedCountry){
        //     let selectedState = this.itemsStates.find(s=>this.normalizeString(s.sName)===this.normalizeString(addressComponents[i].long_name))
        //     this.warehouseState = selectedState.sStateId
        //   }

        //   if(addressComponents[i].types.includes('postal_code')){
        //     //codigo postal
        //     this.warehousePostalCode = addressComponents[i].long_name
        //   }

        //   //administrative_area_level_1 es el type que usa google para la ciudad
        //   if(addressComponents[i].types.includes('locality')){
        //     //codigo postal
        //     this.warehouseCity = addressComponents[i].long_name
        //   }
        // }
      }
    },
    normalizeString: function(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    addWarehouse: function() {
      this.bLoading = true;
      const payload = {
        oWarehouse: {
          sName: this.warehouseName,
          dPrice: this.warehousePrice,
          dCustomsFee: this.warehouseCustomsPrice,
          sPhoneNumber: this.warehousePhoneNumber,
          sEmail: this.warehouseEmail,
        },
        oLocation: {
          sLocationAddress: this.wahrehouseAddress,
          sLocationOptionalAddress: this.wahrehouseAddressOptional,
          sLocationCity: this.warehouseCity,
          sLocationStateId: this.warehouseState,
          sLocationZipCode: this.warehousePostalCode,
          dLocationLatitude: this.center.lat,
          dLocationLongitude: this.center.lng,
        },
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/administrators/${this.$store.state.sAdministratorId}/warehouses`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;
          this.mixSuccess(response.data.message);
          this.closeButton();
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    handleResize: function() {
      if (window.innerWidth >= 960) {
        this.screenWidth = 80;
      } else {
        this.screenWidth = 100;
      }
    },
    closeButton: function() {
      this.$store.commit("setDialogAddWarehouse", {
        active: false,
      });
      this.warehouseName = "";
      this.warehousePrice = "";
      this.warehouseCustomsPrice = "";
      this.wahrehouseAddress = "";
      this.wahrehouseAddressOptional = "";
      this.warehouseCountry = "";
      this.warehouseState = "";
      this.warehouseCity = "";
      this.warehousePostalCode = "";
      this.warehousePhoneNumber = "";
      this.warehouseEmail = "";
      this.warehouseLatitude = "";
      this.warehouseLongitude = "";
      // this.$emit('sentEmptyUbication')
    },
    emitClose: function() {
      this.$store.commit("setDialogAddWarehouse", {
        active: false,
      });
      this.warehouseName = "";
      this.warehousePrice = "";
      this.warehouseCustomsPrice = "";
      this.wahrehouseAddress = "";
      this.wahrehouseAddressOptional = "";
      this.warehouseCountry = "";
      this.warehouseState = "";
      this.warehouseCity = "";
      this.warehousePostalCode = "";
      this.warehousePhoneNumber = "";
      this.warehouseEmail = "";
      this.warehouseLatitude = "";
      this.warehouseLongitude = "";
      // this.$emit('sentEmptyUbication')
    },
    getCountries: function() {
      //post credentials and get access token from laravel backend
      DB.get(`${URI}/api/v1/${this.selectLanguage}/locations/countries`)
        .then((response) => {
          this.itemsCountries = response.data.results;

          this.warehouseCountry = response.data.results[0].sCountryId;
          this.getStates();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    selecState: function() {
      this.state = "";
      this.getStates();
    },
    getStates: function() {
      //post credentials and get access token from laravel backend
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/locations/countries/${this.warehouseCountry}/states`
      )
        .then((response) => {
          this.itemsStates = response.data.results;
          if (this.oPlace) {
            const addressComponents = this.oPlace.address_components;
            for (let i = 0; i < addressComponents.length; i++) {
              //administrative_area_level_1 es el type que usa google para los estados
              if (
                addressComponents[i].types.includes(
                  "administrative_area_level_1"
                )
              ) {
                let selectedState = this.itemsStates.find(
                  (s) =>
                    this.normalizeString(s.sName) ===
                    this.normalizeString(addressComponents[i].long_name)
                );
                this.warehouseState = selectedState.sStateId;
              }

              if (addressComponents[i].types.includes("postal_code")) {
                //codigo postal
                this.warehousePostalCode = addressComponents[i].long_name;
              }

              //administrative_area_level_1 es el type que usa google para la ciudad
              if (addressComponents[i].types.includes("locality")) {
                //ciudad
                this.warehouseCity = addressComponents[i].long_name;
              }
            }
          }
          // this.warehouseState = response.data.results[0].sStateId;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    soloNumbers: function(evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    replaceBadInputs: function(val) {
      // Replace impossible inputs as the appear
      val = val.replace(/[^\dh:]/, "");
      val = val.replace(/^[^0-2]/, "");
      val = val.replace(/^([2-9])[4-9]/, "$1");
      val = val.replace(/^\d[:h]/, "");
      val = val.replace(/^([01][0-9])[^:h]/, "$1");
      val = val.replace(/^(2[0-3])[^:h]/, "$1");
      val = val.replace(/^(\d{2}[:h])[^0-5]/, "$1");
      val = val.replace(/^(\d{2}h)./, "$1");
      val = val.replace(/^(\d{2}:[0-5])[^0-9]/, "$1");
      val = val.replace(/^(\d{2}:\d[0-9])./, "$1");
      return val;
    },
    funcKeyUp: function(e) {
      var val = e.target.value;
      var lastLength;
      do {
        // Loop over the input to apply rules repeately to pasted inputs
        lastLength = val.length;
        val = this.replaceBadInputs(val);
      } while (val.length > 0 && lastLength !== val.length);
      return (e.target.value = val);
    },
    funcBlur: function(e) {
      var val = e.target.value;
      val = /^(([01][0-9]|2[0-3])h)|(([01][0-9]|2[0-3]):[0-5][0-9])$/.test(val)
        ? val
        : "";
      return (e.target.value = val);
    },
  },
  computed: {
    // return create contact dialog state
    DialogAddWarehouse() {
      return this.$store.state.DialogAddWarehouse;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
    validateForm: function() {
      return (
        this.warehouseName !== "" &&
        this.warehousePrice !== "" &&
        this.warehouseCustomsPrice !== "" &&
        this.wahrehouseAddress !== "" &&
        this.warehouseCountry !== "" &&
        this.warehouseState !== "" &&
        this.warehouseCity !== "" &&
        this.warehousePostalCode !== "" &&
        this.warehousePhoneNumber !== "" &&
        this.warehouseEmail !== "" &&
        this.reponsableWarehouse !== "" &&
        this.warehouseLatitude !== "" &&
        this.warehouseLongitude !== "" &&
        this.warehouseShippingLimitTime !== "" &&
        this.warehouseMondayFrom !== "" &&
        this.warehouseMondayTo !== "" &&
        this.warehouseTuesdayFrom !== "" &&
        this.warehouseTuesdayTo !== "" &&
        this.warehouseWednesdayFrom !== "" &&
        this.warehouseWednesdayTo !== "" &&
        this.warehouseThursdayFrom !== "" &&
        this.warehouseThursdayTo !== "" &&
        this.warehouseFridayFrom !== "" &&
        this.warehouseFridayTo !== "" &&
        this.warehouseSaturdayFrom !== "" &&
        this.warehouseSaturdayTo !== "" &&
        this.warehouseSundayFrom !== "" &&
        this.warehouseSundayTo !== ""
      );
    },
  },
  watch: {
    DialogAddWarehouse() {
      if (this.DialogAddWarehouse) {
        this.$store.commit("setMenuSidebar", { active: false });
      }
    },
    selectLanguage: function() {
      if (this.selectLanguage) {
        this.texts = FILE.warehouseTexts[this.selectLanguage];
      }
    },
  },
};
